import React from "react";
import Typography from "@material-ui/core/Typography";
import "./ThreeGoodThings.css";

const ThreeGoodThings = ({
  changeStep = (f) => f,
  changeBack = (f) => f,
  gratitudeScore,
  setGratitudeScore = (f) => f,
}) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  const handleChangeForward = () => {
    let newscore = gratitudeScore;
    if (newscore >= 2) {
      changeStep();
    } else {
      setGratitudeScore((newscore += 1));
      changeStep();
    }
  };
  return (
    <div className="threegoodthings">
      <Typography component={"div"}>
        <h2>
          <b>Three Good Things Exercise</b>
        </h2>
      </Typography>
      <Typography component={"div"}>
        <p>
          We are now going to do an exercise called the
          <em>
            <u> Three Good Things. </u>
          </em>
          This exercise is divided into two parts:
        </p>
      </Typography>

      <hr />
      <Typography component={"div"}>
        <p className="top">
          In the <strong> first part, </strong> you will <b>think</b> about the{" "}
          <u> last few days</u> and write down three good things that you are
          grateful for (things that you appreciate or things that you are
          thankful for). A <strong> 'good thing'</strong> is{" "}
          <strong>anything</strong>. Good things are different for everyone. For
          example, John can be glad because he ate a very delicious meal of
          chapati and nyama choma and Mary can be happy because she talked with
          her friend. Other good things can include passing examinations, having
          school fees or your parents buying a gift for you.
        </p>
      </Typography>

      <Typography component={"div"}>
        <p className="bottom">
          In the <strong>second part,</strong> you will think about each good
          thing. You will ask yourself:{" "}
          <strong>
            why did this good thing happen to me? What does this mean to you?
            How can you increase the chances of this thing happening to you
            again in the future?
          </strong>{" "}
          After thinking about this, you will <b> write your thought </b>next to
          the good thing
        </p>
      </Typography>

      <hr />
      <Typography component={"div"}>
        <h3>Example:</h3>
      </Typography>
      <Typography component={"div"}>
        <p className="example-p">
          Let us show you an example from a boy called Eric who is in Form 2:
        </p>
      </Typography>

      <div className="table">
        <Typography component={"div"}>
          <p>
            Over the weekend, Erick went to watch football on Saturday and his
            team Arsenal won. Eric is grateful because he enjoys football and
            his favorite player Aubameyang scored. On Sunday Eric went with his
            sister to Uhuru Park in the city. Erick is grateful because he loves
            his sister, and he is happy he spent time with her. On Monday, Erick
            did a History exam. Erick does not like History that much, but Erick
            got a passing grade in the exam. Erick is grateful because he cares
            about his studies and he did not fail in History.
          </p>
        </Typography>
        <Typography component={"div"}>
          <table>
            <thead>
              <tr>
                <th>Good Things</th>
                <th>My thoughts</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="colored">1. My football team won a game </td>
                <td className="colored">
                  1. I am happy because I enjoy football and liked the goal
                </td>
              </tr>
              <tr>
                <td>2. My sister and I went to Uhuru Park </td>
                <td>2. I am happy when I spend time with my sister</td>
              </tr>
              <tr>
                <td className="colored">
                  3. I did not fail in the History exam
                </td>
                <td className="colored">
                  3. I am happy because I like my studies and want to do well
                </td>
              </tr>
            </tbody>
          </table>
        </Typography>
      </div>
      <hr />
      <Typography component={"div"}>
        <p className="left-placed">
          When you are ready to do the Three Good Things exercise, click the
          arrow to start.
        </p>
      </Typography>

      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
};

export default ThreeGoodThings;
