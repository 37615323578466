import React from "react";
import Typography from "@material-ui/core/Typography";
import "./Gratitude.css";
import gratitudeBrain from "../images/gratitude-brain.png";
import gratitude from "../images/gratitude.png";

const Gratitude = ({
  changeStep = (f) => f,
  changeBack = (f) => f,
  gratitudeScore,
  setGratitudeScore = (f) => f,
}) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  const handleChangeForward = () => {
    let newscore = gratitudeScore;
    if (newscore >= 1) {
      changeStep();
    } else {
      setGratitudeScore((newscore += 1));
      changeStep();
    }
  };

  return (
    <div className="gratitude">
      <Typography component={"div"}>
        <h2>
          <b>What is gratitude?</b>
        </h2>
      </Typography>

      <img id="intro-image" src={gratitude} alt="gratitude" />
      <Typography component={"div"}>
        <p className="gratitude-left">
          Gratitude is <b> feeling grateful, thankful, and happy</b>{" "}
          <strong> for things in your life.</strong> You can feel grateful for
          <b> people </b> like your <u> parents, your brothers and sisters ,</u>{" "}
          your <strong> teachers, your friends, and even God.</strong> You can
          also feel grateful for <b>things</b> like{" "}
          <u> being in school, the environment, hobbies, and food.</u>
        </p>
      </Typography>

      <img src={gratitudeBrain} alt="gratitude-brain" />
      <Typography component={"div"}>
        <p className="gratitude-left">
          Scientists say that being grateful makes a{" "}
          <b> person feel happier, more successful</b>, and{" "}
          <b> have better relationships with their friends and family</b>. If we
          try and be more grateful, we will be able to{" "}
          <strong>deal with challenges in our lives.</strong>
        </p>
      </Typography>
      <Typography component={"div"}>
        <p className="gratitude-left">
          Sometimes, when we <u> feel sad or upset,</u> it is{" "}
          <strong>hard</strong> to feel grateful.
        </p>
      </Typography>
      <Typography component={"div"}>
        <p className="gratitude-left">
          But did you know you can <i> become more grateful with practice? </i>
          If you <b> try and focus,</b> you can become grateful, enjoy the good
          things in your life even more, and feel happier.
        </p>
      </Typography>

      <Typography component={"div"}>
        <p className="gratitude-left">
          Today, we are going to try a writing activity that can help us become
          more grateful.
        </p>
      </Typography>
      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Gratitude;
