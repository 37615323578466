import React from "react";
import "./Intro.css";
import logo from "../images/shamiri-logo.png";

const Outro = () => {
  return (
    <div className="introduction">
      <div className="introduction__image">
        <img effect="blur" src={logo} alt="shamiri logo" />
      </div>
      <div className="introduction__intro">
        <hr />

        <b>
          Thank you for participating in the Shamiri (Thrive!) Online Program.
        </b>
        <hr />
      </div>
    </div>
  );
};

export default Outro;
