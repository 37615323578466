import React from "react";
import Typography from "@material-ui/core/Typography";
import brain from "../images/brain.jpg";
import brainGrow from "../images/brain-grow.png";
import "./Growth.css";

function Growth({
  changeStep = (f) => f,
  // changeBack = (f) => f,
  growthScore,
  setGrowthScore = (f) => f,
}) {
  const handleChangeForward = () => {
    let newscore = growthScore;
    if (newscore >= 1) {
      changeStep();
    } else {
      setGrowthScore((newscore += 1));
      changeStep();
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();

  return (
    <div className="growth">
      <Typography component={"div"}>
        <h2 className="growth__header">
          <b>What is growth?</b>
        </h2>
      </Typography>

      <div className="images">
        <img src={brain} alt="brain" />
      </div>
      <Typography component={"div"}>
        <p>
          Growth is the idea that
          <b> we can change and become better at things.</b> We can grow in many
          different ways: we can <b> grow in our relationships </b>, we can
          <b> become better at math</b>, we can{" "}
          <b> become more generous and kinder</b>, and we can{" "}
          <b> become happier and satisfied with life.</b>
        </p>
      </Typography>
      <Typography component={"div"}>
        <p>
          In fact, scientists who <strong>study</strong> the brain say that the
          brain <strong> changes</strong> when you <u> grow</u> in these areas.
          But, how does the brain <u> grow</u>?
        </p>
      </Typography>

      <div className="images">
        <img src={brainGrow} alt="brain-grow" />
      </div>
      <Typography component={"div"}>
        <p>
          <strong>Imagine </strong> you go to the gym and
          <strong> exercise</strong>. After a while, your muscles{" "}
          <b>begin to grow and become stronger. </b> When you stop exercising,
          the muscles <u> stop growing</u> and <u> might become smaller.</u>
        </p>
      </Typography>
      <Typography component={"div"}>
        <p>
          The <strong>brain</strong> works in the <u>same way</u>. Just like a
          muscle, your brain <b>gets stronger </b> the more you use it. This
          happens because our brain cells grow and make{" "}
          <b> many more small connections when we learn. </b>
          The more you challenge your mind,{" "}
          <b> the more the brain cells grow.</b> Things that you found hard,
          like algebra or chemistry, <b>start becoming easier.</b>
        </p>
      </Typography>
      <div className="btn growth__intro">
        {/* <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div> */}
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
}

export default Growth;
