import React from "react";
import Typography from "@material-ui/core/Typography";
import roadNetwork from "../images/road-network.png";
import "./GrowthPartOne.css";

function GrowthPartOne({
  changeStep = (f) => f,
  changeBack = (f) => f,
  growthScore,
  setGrowthScore = (f) => f,
}) {
  const handleChangeForward = () => {
    let newscore = growthScore;
    if (newscore >= 2) {
      changeStep();
    } else {
      setGrowthScore((newscore += 1));
      changeStep();
    }
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  return (
    <div className="growthpartone">
      <div className="images">
        <img src={roadNetwork} alt="road-network" />
      </div>
      <Typography component={"div"}>
        <p>
          Another way that we can <strong>think</strong> about our
          <b> brain growing </b> is by <strong>imagining</strong> the brain as a
          network of roads. <strong>Imagine</strong> the brain as having
          different roads inside it that it uses when
          <strong> you think, feel, or do different things.</strong> Some of
          these roads are used <u> a lot</u> of times, like the road you use to
          learn Swahili. Other roads are not used as much. When you use a road,
          the road becomes
          <strong> better</strong> as you are now more and more used to it.
        </p>
      </Typography>
      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
}

export default GrowthPartOne;
