import React from "react";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Button from "@material-ui/core/Button";
import { Animated } from "react-animated-css";

import smallTree from "../images/small-tree.jpg";

import "./GratitudeEmailOutro.css";

const GratitudeEmailOutro = ({ changeStep = (f) => f }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  return (
    <div className="gratitude__emailintro">
      <div className="gratitude__emailintro__content">
        <Typography component={"div"}>
          <p>
            We hope that you enjoyed the lesson about <b> gratitude.</b> If you
            take a minute every day or every week to reflect on good things that
            happen <br />
            to you, you can become more grateful and feel happier.
          </p>
        </Typography>

        <Animated
          animationIn="zoomIn"
          animationOut="fadeOut"
          isVisible={true}
          animationInDuration={3000}
        >
          <div className="smalltree">
            <img src={smallTree} alt="small tree" />
          </div>
        </Animated>
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              Gratitude is feeling grateful, thankful, and happy for things in
              your life.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              Being grateful makes a person feel happier, more successful , and
              have better relationships with their friends and family.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              If we try and be more grateful, we will be able to deal with
              challenges in our lives.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              You can become more grateful with practice.
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        <hr />
      </div>
      <Button
        onClick={() => changeStep()}
        size="large"
        variant="contained"
        color="primary"
      >
        continue
      </Button>
      <hr />
    </div>
  );
};

export default GratitudeEmailOutro;
