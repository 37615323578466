import React from "react";
import Typography from "@material-ui/core/Typography";
import mwabe from "../images/mwabe.jpg";
import "./GrowthStory.css";

function GrowthStory({
  changeStep = (f) => f,
  changeBack = (f) => f,
  growthScore,
  setGrowthScore = (f) => f,
}) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  const handleChangeForward = () => {
    let newscore = growthScore;
    if (newscore >= 3) {
      changeStep();
    } else {
      setGrowthScore((newscore += 1));
      changeStep();
    }
  };
  return (
    <div className="growthstory">
      <Typography component={"div"}>
        <p>
          Now, let us read a <b> growth story</b> of Eunice Mwabe, a student
          from Kenya who <strong> overcame a challenge by using growth.</strong>
        </p>
      </Typography>

      <hr />
      <div className="growthstory__content">
        <div className="image">
          <figure>
            <img effect="blur" src={mwabe} alt="mwabe" />
            <figcaption>
              <b>Eunice Mwabe </b> is from Migori County and went to school in
              Kiambu County. After KCSE, she went to Harvard and graduated in
              2019 and is now teaching at the African Leadership Academy in
              South Africa.
            </figcaption>
          </figure>
        </div>
        <div className="story">
          <Typography component={"div"}>
            <h3>
              <strong>My Growth Story – Learning to Communicate.</strong>
            </h3>
          </Typography>
          <Typography>
            <q>
              Our families are a major part of who we are. Everyone hopes to
              have strong relationships with their parents, aunts, uncles, and
              siblings. But from a young age, my sister and I never got along.
              We always fought and yelled at each other. It was difficult to be
              in the same room because we were very different and didn’t listen
              to each other. <br /> I felt very sad that my sister and I could
              not have a strong relationship, go out and do things together, or
              have interesting conversations. Finally, when I entered high
              school, I realized that my problems with my sister would not
              change on their own. They would only change if I made an effort to
              change my behavior. <br /> From that moment on, I made an effort
              to be more compassionate, kind, and became less angry. I listened
              to her more, and paid attention to what she had to say. Slowly, we
              grew closer. I am now proud to say that my sister and I are closer
              than I ever thought we could be.
            </q>
          </Typography>
        </div>
      </div>
      <hr />
      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
}

export default GrowthStory;
