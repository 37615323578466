import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "./VirtuesIntro.css";

const VirtuesIntro = ({ changeStep }) => {
  return (
    <div className="virtues-intro">
      <Typography component={"div"}>
        <p>
          We hope that you enjoyed the lesson about <b> gratitude.</b> If you
          take a minute every day or every week to reflect on good things that
          happen <br />
          to you, you can become more grateful and feel happier.
        </p>
      </Typography>
      <Typography component={"div"}>
        <p>
          Now, we will learn a little bit about <b> virtues.</b>
        </p>
      </Typography>

      <hr />
      <div className="press">
        <Typography component={"div"}>
          Press <b>the button</b> to start
        </Typography>
        <hr />
        <Button
          onClick={() => changeStep()}
          size="large"
          variant="contained"
          color="primary"
        >
          Start
        </Button>
      </div>
    </div>
  );
};

export default VirtuesIntro;
