import firebase from "firebase/app";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBaFMKGWPAmQXzcnsNz9-2am9SoJR7HuEM",
  authDomain: "thive-online-authentication.firebaseapp.com",
  projectId: "thive-online-authentication",
  storageBucket: "thive-online-authentication.appspot.com",
  messagingSenderId: "810589943177",
  appId: "1:810589943177:web:ca0997c87fd6ec16899d58",
  measurementId: "G-13Q3J062LW",
};

const app = firebase.initializeApp(firebaseConfig);

// Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const auth = app.auth();
export default app;
