import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "./FeedbackThree.css";

const FeedbackThree = ({
  changeStep,
  age,
  gender,
  handleApiCall = (f) => f,
  setAge = (f) => f,
  setGender = (f) => f,
}) => {
  const handleFeedback = () => {
    if (gender === "" || age === 0) {
      window.alert("Please fill out each of the questions");
    } else {
      // API CALL TO BACKEND - Reference
      handleApiCall();
      changeStep();
    }
  };

  return (
    <div className="feedback__three">
      <div onChange={(e) => setAge(e.target.value)} className="quizes age">
        <Typography component={"div"}>
          <p>
            How <b>old </b>are you?
          </p>
        </Typography>

        <label htmlFor="a">
          <input type="radio" value={12} name="age" id="a" />
          12
        </label>
        <label htmlFor="b">
          <input type="radio" value={13} name="age" id="b" />
          13
        </label>
        <label htmlFor="c">
          <input type="radio" value={14} name="age" id="c" />
          14
        </label>
        <label htmlFor="d">
          <input type="radio" value={15} name="age" id="d" />
          15
        </label>
        <label htmlFor="e">
          <input type="radio" value={16} name="age" id="e" />
          16
        </label>
        <label htmlFor="f">
          <input type="radio" value={17} name="age" id="f" />
          17
        </label>
        <label htmlFor="g">
          <input type="radio" value={18} name="age" id="g" />
          18
        </label>
        <label htmlFor="h">
          <input type="radio" value={19} name="age" id="h" />
          19
        </label>
        <label htmlFor="i">
          <input type="radio" value="Other" name="age" id="i" />
          Other
        </label>
      </div>
      <div
        onChange={(e) => setGender(e.target.value)}
        className="quizes gender"
      >
        <Typography component={"div"}>
          <p>
            What is your <b> gender ?</b>
          </p>
        </Typography>

        <label htmlFor="n">
          <input type="radio" value="Male" name="gender" id="n" />
          Male
        </label>
        <label htmlFor="o">
          <input type="radio" value="Female" name="gender" id="o" />
          Female
        </label>
      </div>
      <div className="button__container">
        <Button
          onClick={() => handleFeedback()}
          variant="contained"
          color="default"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FeedbackThree;
