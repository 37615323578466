import React, { useState, useEffect } from "react";
// import DataDisplay from "./DataDisplay";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import AccountCircle from "@material-ui/icons/AccountCircle";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Table from "@material-ui/core/Table";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Alert from "@material-ui/lab/Alert";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    color: "red",
  },
  cont: {
    marginLeft: 465,
  },
  left: {
    marginLeft: 20,
  },
  logout: {
    marginLeft: 20,
  },
}));

const Admin = () => {
  const [error, setError] = useState("");

  const [data, setData] = useState([
    {
      consent: true,
      effortOvercome: "No data available from the database at the moment",
    },
  ]);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch("posts/");
      const result = await resp.json();
      setData(result);
    };
    fetchData();
  }, [data]);

  // const handleExport = () => {
  //   history.push("/download");
  // };

  // logout functionality
  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      // history.push("/");
      history.push("/signin");
    } catch (err) {
      setError("Failed to log out");
    }
  };

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
  // sign up settings
  const { logout, currentUser } = useAuth();

  const classes = useStyles();
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <ElevationScroll>
          <AppBar>
            <Toolbar>
              <Typography variant="h6">Shamiri Admin Page</Typography>
              {/* <Link href="http://157.230.178.188/download"> */}
              <Link href="/download">
                <Button
                  variant="outlined"
                  color="secondary"
                  // onClick={() => handleExport()}
                  className={classes.cont}
                >
                  <GetAppIcon />
                  Export
                </Button>
              </Link>
              <AccountCircle className={classes.left} /> {currentUser.email}
              <Button
                variant="contained"
                onClick={() => handleLogout()}
                className={classes.logout}
              >
                Log Out
              </Button>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        {error && <Alert severity="error">{error}</Alert>}

        <Paper className={classes.paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>id</TableCell>
                <TableCell>Created_Date</TableCell>
                <TableCell>challengeFaced</TableCell>
                <TableCell>effortOvercome</TableCell>
                <TableCell>improveFromChallenge</TableCell>
                <TableCell>goodThingsOne</TableCell>
                <TableCell>goodThingsTwo</TableCell>
                <TableCell>goodThingsThree</TableCell>
                <TableCell>myThoughtsOne</TableCell>
                <TableCell>myThoughtsTwo</TableCell>
                <TableCell>myThoughtsThree</TableCell>
                <TableCell>virtuesThatMatter</TableCell>
                <TableCell>virtueChosen</TableCell>
                <TableCell>whyVirtue</TableCell>
                <TableCell>usedVirtue</TableCell>
                <TableCell>feelUsingVirtue</TableCell>
                <TableCell>contUsingVirtue</TableCell>
                <TableCell>helpfulthingLearned</TableCell>
                <TableCell>leastHelpfulthingLearned</TableCell>
                <TableCell>growthEmail</TableCell>
                <TableCell>lessonsFeedback</TableCell>
                <TableCell>growthLessonsFeedback</TableCell>
                <TableCell>gratitudeLessonsFeedback</TableCell>
                <TableCell>virtuesLessonsFeedback</TableCell>
                <TableCell>applyLessonsFeedback</TableCell>
                <TableCell>opinionFeedback</TableCell>
                <TableCell>age</TableCell>
                <TableCell>gender</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((value) => (
                <TableRow key={value._id}>
                  <TableCell>{value._id}</TableCell>
                  <TableCell>{value.created_date}</TableCell>
                  <TableCell>{value.challengeFaced}</TableCell>
                  <TableCell>{value.effortOvercome}</TableCell>
                  <TableCell>{value.improveFromChallenge}</TableCell>
                  <TableCell>{value.goodThingsOne}</TableCell>
                  <TableCell>{value.goodThingsTwo}</TableCell>
                  <TableCell>{value.goodThingsThree}</TableCell>
                  <TableCell>{value.myThoughtsOne}</TableCell>
                  <TableCell>{value.myThoughtsTwo}</TableCell>
                  <TableCell>{value.myThoughtsThree}</TableCell>
                  <TableCell>{value.virtuesThatMatter}</TableCell>
                  <TableCell>{value.virtueChosen}</TableCell>
                  <TableCell>{value.whyVirtue}</TableCell>
                  <TableCell>{value.usedVirtue}</TableCell>
                  <TableCell>{value.feelUsingVirtue}</TableCell>
                  <TableCell>{value.contUsingVirtue}</TableCell>
                  <TableCell>{value.helpfulthingLearned}</TableCell>
                  <TableCell>{value.leastHelpfulthingLearned}</TableCell>
                  <TableCell>{value.growthEmail}</TableCell>
                  <TableCell>{value.lessonsFeedback}</TableCell>
                  <TableCell>{value.growthLessonsFeedback}</TableCell>
                  <TableCell>{value.gratitudeLessonsFeedback}</TableCell>
                  <TableCell>{value.virtuesLessonsFeedback}</TableCell>
                  <TableCell>{value.applyLessonsFeedback}</TableCell>
                  <TableCell>{value.opinionFeedback}</TableCell>
                  <TableCell>{value.age}</TableCell>
                  <TableCell>{value.gender}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.seeMore}>
            <Link color="primary" href="#" onClick={preventDefault}>
              Total Data Available {data.length}
            </Link>
          </div>
        </Paper>
      </React.Fragment>
    </>
  );
};

export default Admin;
