import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./GrowthExercise.css";

const GrowthExercise = ({
  changeStep,
  challengeFaced,
  effortOvercome,
  improveFromChallenge,
  setChallengeFaced = (f) => f,
  setImproveFromChallenge = (f) => f,
  setEffortOvercome = (f) => f,
  growthScore,
  setGrowthScore = (f) => f,
}) => {
  const [errorOne, setErrorOne] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [errorThree, setErrorThree] = useState(false);

  let newscore = growthScore;

  const handleInputCheck = () => {
    if (challengeFaced === "") {
      setErrorOne(true);
    } else {
      setErrorOne(false);
      setGrowthScore((newscore += 0.5));
    }
    if (effortOvercome === "") {
      setErrorTwo(true);
    } else {
      setErrorTwo(false);
      setGrowthScore((newscore += 1));
    }
    if (improveFromChallenge === "") {
      setErrorThree(true);
    } else {
      setErrorThree(false);
      setGrowthScore((newscore += 0.5));
    }

    if (
      challengeFaced === "" ||
      effortOvercome === "" ||
      improveFromChallenge === ""
    ) {
      let retval = window.confirm(
        "Some fields are empty. Do you want to continue?"
      );
      if (retval) {
        changeStep();
      } else {
        return;
      }
    } else {
      changeStep();
    }
  };

  return (
    <div className="growth__exercise">
      <div className="growth__content">
        <Typography component={"div"}>
          <p className="growth__exercise__intro">
            <b> Exercise:</b> Now you are going to{" "}
            <strong>write your own growth story </strong>
            below.
          </p>
        </Typography>

        <hr />
        <Typography component={"div"}>
          <p>
            Please think of a time you faced a challenge and used effort or
            strategies to overcome that challenge. You might think about a time
            when you tried something new and struggled at first.
          </p>
        </Typography>

        <hr />
        <Typography component={"div"}>
          <div className="growth__exercise__qstns">
            <p>What was the challenge you faced?</p>
            <p>(Please write 1-2 sentences in the box below)</p>
          </div>
        </Typography>

        <TextField
          id="outlined-full-width"
          label="Challenge you faced?"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorOne}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={(e) => setChallengeFaced(e.target.value)}
          value={challengeFaced}
        />

        <div className="growth__exercise__qstns">
          <Typography component={"div"}>
            <p>How did you use effort to overcome the challenge?</p>
            <p>What strategies did you use?</p>
            <p>(Please write 1-2 sentences in the box below)</p>
          </Typography>
        </div>
        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorTwo}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={(e) => setEffortOvercome(e.target.value)}
          value={effortOvercome}
        />
        <div className="growth__exercise__qstns">
          <Typography component={"div"}>
            <p>
              How did you learn, grow, or improve as a result of the challenge?
            </p>
            <p>(Please write 1-2 sentences in the box below)</p>
          </Typography>
        </div>
        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorThree}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={(e) => setImproveFromChallenge(e.target.value)}
          value={improveFromChallenge}
        />
      </div>
      <div className="arrow-button">
        <Button variant="outlined" onClick={() => handleInputCheck()}>
          Submit Growth Story
        </Button>
      </div>
    </div>
  );
};

export default GrowthExercise;
