import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import "./Feedback.css";

const Feedback = ({
  changeStep,
  lessonsFeedback,
  growthLessonsFeedback,
  gratitudeLessonsFeedback,
  virtuesLessonsFeedback,
  applyLessonsFeedback,
  opinionFeedback,
  setLessonsFeedback = (f) => f,
  setGrowthLessonsFeedback = (f) => f,
  setGratitudeLessonsFeedback = (f) => f,
  setVirtuesLessonsFeedback = (f) => f,
  setApplyLessonsFeedback = (f) => f,
  setOpinionFeedback = (f) => f,
}) => {
  // CHECKING IF VALUES ARE EMPTY
  const handleStepChange = () => {
    if (
      lessonsFeedback === "" ||
      growthLessonsFeedback === "" ||
      gratitudeLessonsFeedback === "" ||
      virtuesLessonsFeedback === "" ||
      applyLessonsFeedback === "" ||
      opinionFeedback === ""
    ) {
      let valCont = window.confirm(
        "Some fields are empty. Do you want to continue ?"
      );
      if (valCont) {
        changeStep();
      } else {
        return;
      }
    } else {
      changeStep();
    }
  };

  return (
    <div className="feedback">
      <Typography>
        <p className="feedback__intro">
          <b> Instructions: </b>Please rate how much you agree/disagree with
          following statements.
        </p>
      </Typography>

      <hr />
      <div
        onChange={(e) => setLessonsFeedback(e.target.value)}
        className="quizes"
      >
        <p>I understood the lessons in this program.</p>
        <label htmlFor="a">
          <input type="radio" id="a" name="lesson" value="Strongly Disagree" />
          Strongly Disagree
        </label>
        <label htmlFor="b">
          <input type="radio" id="b" name="lesson" value="Somewhat Disagree" />
          Somewhat Disagree
        </label>
        <label htmlFor="c">
          <input type="radio" id="c" name="lesson" value="No Opinion" />
          No Opinion
        </label>
        <label htmlFor="d">
          <input type="radio" id="d" name="lesson" value="Somewhat Agree" />
          Somewhat Agree
        </label>
        <label htmlFor="e">
          <input type="radio" id="e" name="lesson" value="Strongly Agree" />
          Strongly Agree
        </label>
      </div>
      <hr />
      <div
        onChange={(e) => setGrowthLessonsFeedback(e.target.value)}
        className="quizes"
      >
        <p>I enjoyed the lessons on growth.</p>
        <label htmlFor="f">
          <input type="radio" value="Strongly Disagree" name="growth" id="f" />
          Strongly Disagree
        </label>
        <label htmlFor="g">
          <input type="radio" value="Somewhat Disagree" name="growth" id="g" />
          Somewhat Disagree
        </label>
        <label htmlFor="h">
          <input type="radio" value="No Opinion" name="growth" id="h" />
          No Opinion
        </label>
        <label htmlFor="i">
          <input type="radio" value="Somewhat Agree" name="growth" id="i" />
          Somewhat Agree
        </label>
        <label htmlFor="j">
          <input type="radio" value="Strongly Agree" name="growth" id="j" />
          Strongly Agree
        </label>
      </div>
      <hr />
      <div
        onChange={(e) => setGratitudeLessonsFeedback(e.target.value)}
        className="quizes"
      >
        <p>I enjoyed the lesson on gratitude.</p>
        <label htmlFor="k">
          <input
            type="radio"
            value="Strongly Disagree"
            name="gratitude"
            id="k"
          />
          Strongly Disagree
        </label>
        <label htmlFor="l">
          <input
            type="radio"
            value="Somewhat Disagree"
            name="gratitude"
            id="l"
          />
          Somewhat Disagree
        </label>
        <label htmlFor="m">
          <input type="radio" value="No Opinion" name="gratitude" id="m" />
          No Opinion
        </label>
        <label htmlFor="n">
          <input type="radio" value="Somewhat Agree" name="gratitude" id="n" />
          Somewhat Agree
        </label>
        <label htmlFor="o">
          <input type="radio" value="Strongly Agree" name="gratitude" id="o" />
          Strongly Agree
        </label>
      </div>
      <hr />
      <div
        onChange={(e) => setVirtuesLessonsFeedback(e.target.value)}
        className="quizes"
      >
        <p>I enjoyed the lesson on virtues.</p>
        <label htmlFor="p">
          <input type="radio" value="Strongly Disagree" name="virtues" id="p" />
          Strongly Disagree
        </label>
        <label htmlFor="q">
          <input type="radio" value="Somewhat Disagree" name="virtues" id="q" />
          Somewhat Disagree
        </label>
        <label htmlFor="r">
          <input type="radio" value="No Opinion" name="virtues" id="r" />
          No Opinion
        </label>
        <label htmlFor="s">
          <input type="radio" value="Somewhat Agree" name="virtues" id="s" />
          Somewhat Agree
        </label>
        <label htmlFor="t">
          <input type="radio" value="Strongly Agree" name="virtues" id="t" />
          Strongly Agree
        </label>
      </div>
      <hr />
      <div
        onChange={(e) => setApplyLessonsFeedback(e.target.value)}
        className="quizes"
      >
        <p>
          I can apply growth, gratitude, and virtues to my life in the future.
        </p>
        <label htmlFor="u">
          <input type="radio" value="Strongly Disagree" name="apply" id="u" />
          Strongly Disagree
        </label>
        <label htmlFor="v">
          <input type="radio" value="Somewhat Disagree" name="apply" id="v" />
          Somewhat Disagree
        </label>
        <label htmlFor="w">
          <input type="radio" value="No Opinion" name="apply" id="w" />
          No Opinion
        </label>
        <label htmlFor="x">
          <input type="radio" value="Somewhat Agree" name="apply" id="x" />
          Somewhat Agree
        </label>
        <label htmlFor="y">
          <input type="radio" value="Strongly Agree" name="apply" id="y" />
          Strongly Agree
        </label>
      </div>
      <hr />
      <div
        onChange={(e) => setOpinionFeedback(e.target.value)}
        className="quizes"
      >
        <p>I think other students would like this program.</p>
        <label htmlFor="one">
          <input type="radio" value="Strongly Disagree" name="like" id="one" />
          Strongly Disagree
        </label>
        <label htmlFor="two">
          <input type="radio" value="Somewhat Disagree" name="like" id="two" />
          Somewhat Disagree
        </label>
        <label htmlFor="three">
          <input type="radio" value="No Opinion" name="like" id="three" />
          No Opinion
        </label>
        <label htmlFor="four">
          <input type="radio" value="Somewhat Agree" name="like" id="four" />
          Somewhat Agree
        </label>
        <label htmlFor="five">
          <input type="radio" value="Strongly Agree" name="like" id="five" />
          Strongly Agree
        </label>
      </div>
      <div className="button__container">
        <Button
          onClick={() => handleStepChange()}
          variant="contained"
          color="default"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default Feedback;
