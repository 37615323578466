import React from "react";
import Typography from "@material-ui/core/Typography";
import "./Virtues.css";
import virtues from "../images/virtues.png";
import happyVirtues from "../images/happy-virtues.jpeg";

const Virtues = ({
  changeStep = (f) => f,
  changeBack = (f) => f,
  virtuesScore,
  setVirtuesScore = (f) => f,
}) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  const handleChangeForward = () => {
    let newscore = virtuesScore;
    if (newscore >= 1) {
      changeStep();
    } else {
      setVirtuesScore((newscore += 1));
      changeStep();
    }
  };
  return (
    <div className="virtues">
      <Typography component={"div"}>
        <h2>
          <strong>
            <b> What are virtues?</b>
          </strong>
        </h2>
      </Typography>

      <img src={virtues} alt="virtues" />
      <Typography component={"div"}>
        <p className="virtues__content">
          You might already be familiar with virtues—virtues are ideas that are
          important to you. Virtues are{" "}
          <u>
            sometimes called <strong>values</strong>
          </u>
          . Examples of virtues include{" "}
          <b> honesty, courage, being a good friend, </b>and{" "}
          <b> working hard.</b> Our virtues can help us{" "}
          <u>
            make good decisions, act in difficult situations, and behave
            properly.
          </u>
        </p>
      </Typography>

      <img src={happyVirtues} alt="happy-virtues" />
      <hr />
      <Typography component={"div"}>
        <p>
          <i>
            “When we take time to notice and reflect on our virtues, we make
            better decisions, we’re more likely to get better grades, and we
            feel happier.”
          </i>
        </p>
      </Typography>

      <hr />
      <Typography component={"div"}>
        <p className="virtues__content">
          Science has shown that <b> remembering our virtues</b> can make us
          <i> happier </i> and help us make <i>better</i> choices. Research
          shows that people who think about their virtues are{" "}
          <span>healthier</span> than people who do not think about their
          virtues. Thinking about our virtues can also <span>improve</span> our{" "}
          <b> energy levels, compassion for others, self-esteem,</b> and{" "}
          <b>our ability to work and study.</b>
        </p>
      </Typography>

      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button
            onClick={() => handleChangeForward()}
            className="arrow-right"
          ></button>
        </div>
      </div>
    </div>
  );
};

export default Virtues;
