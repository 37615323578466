import React from "react";
import Typography from "@material-ui/core/Typography";
import "./ThreeGoodThingsExercise.css";

const ThreeGoodThingsExercise = ({
  changeStep,
  goodThingsOne,
  goodThingsTwo,
  goodThingsThree,
  myThoughtsOne,
  myThoughtsTwo,
  myThoughtsThree,
  setMyThoughtsOne = (f) => f,
  setMyThoughtsTwo = (f) => f,
  setMyThoughtsThree = (f) => f,
  setGoodThingsOne = (f) => f,
  setGoodThingsTwo = (f) => f,
  setGoodThingsThree = (f) => f,
  gratitudeScore,
  setGratitudeScore = (f) => f,
}) => {
  // setting new score initially
  let newScore = gratitudeScore;
  const handleSubmit = () => {
    if (
      goodThingsOne === "" ||
      goodThingsTwo === "" ||
      goodThingsThree === "" ||
      myThoughtsOne === "" ||
      myThoughtsTwo === "" ||
      myThoughtsThree === ""
    ) {
      let varCont = window.confirm(
        "Some fields are empty. Do you want to continue?"
      );
      if (varCont) {
        changeStep();
      } else {
        return;
      }
    } else {
      if (goodThingsOne !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      if (goodThingsTwo !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      if (goodThingsThree !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      if (myThoughtsOne !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      if (myThoughtsTwo !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      if (myThoughtsThree !== "") {
        setGratitudeScore((newScore += 0.5));
      }
      changeStep();
    }
  };

  return (
    <div className="threegoodthingsexercise">
      <Typography component={"div"}>
        <h2>
          <b>Three Good Things Exercise</b>
        </h2>
      </Typography>

      <Typography component={"div"}>
        <p>
          <strong>It is now your turn:</strong>
        </p>
      </Typography>

      <Typography component={"div"}>
        <p>Fill out the table below following these instructions:</p>
      </Typography>

      <Typography component={"div"}>
        <p>
          First, <b>think</b> about the <u>last few days</u>. Write down{" "}
          <strong> three good things</strong> that you are <span>grateful</span>{" "}
          for (things that you appreciate or things that you are thankful for)
          in the first column of the table. A “good thing” is anything. As we
          said earlier, good things are different for everyone.
        </p>
      </Typography>

      <Typography component={"div"}>
        <p>
          Second, after you have written down the good things,<b> think</b>{" "}
          about
          <u> each good thing.</u> Ask yourself{" "}
          <i>
            why did this good thing happen to me? What does this mean to you?
            How can you increase the chances of this thing happening to you
            again in the future?
          </i>{" "}
          Now write one sentence about what you thought next to the good thing.
        </p>
      </Typography>

      <div className="form-table">
        <table>
          <thead>
            <tr>
              <th>Good Things</th>
              <th>My thoughts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="colored">
                1.
                <input
                  value={goodThingsOne}
                  onChange={(e) => setGoodThingsOne(e.target.value)}
                  type="text"
                />
              </td>
              <td className="colored">
                1.
                <input
                  value={myThoughtsOne}
                  onChange={(e) => setMyThoughtsOne(e.target.value)}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>
                2.
                <input
                  value={goodThingsTwo}
                  onChange={(e) => setGoodThingsTwo(e.target.value)}
                  type="text"
                />
              </td>
              <td>
                2.
                <input
                  value={myThoughtsTwo}
                  onChange={(e) => setMyThoughtsTwo(e.target.value)}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td className="colored">
                3.
                <input
                  value={goodThingsThree}
                  onChange={(e) => setGoodThingsThree(e.target.value)}
                  type="text"
                />
              </td>
              <td className="colored">
                3.
                <input
                  value={myThoughtsThree}
                  onChange={(e) => setMyThoughtsThree(e.target.value)}
                  type="text"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="at-center">
        <Typography component={"div"}>
          <p>When you are done click the submit button</p>
        </Typography>
        {/* TODO: ERROR CHECK */}
        <button onClick={() => handleSubmit()}>SUBMIT</button>
      </div>
    </div>
  );
};

export default ThreeGoodThingsExercise;
