import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./VirtueAffirm.css";

const VirtueAffirm = ({
  changeStep,
  virtueChosen,
  whyVirtue,
  usedVirtue,
  feelUsingVirtue,
  contUsingVirtue,
  setVirtueChosen = (f) => f,
  setWhyVirtue = (f) => f,
  setUsedVirtue = (f) => f,
  setFeelUsingVirtue = (f) => f,
  setContUsingVirtue = (f) => f,
  virtuesScore,
  setVirtuesScore = (f) => f,
}) => {
  const [errorOne, setErrorOne] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const [errorThree, setErrorThree] = useState(false);
  const [errorFour, setErrorFour] = useState(false);
  const [errorFive, setErrorFive] = useState(false);

  const handleChangeStep = () => {
    let newScore = virtuesScore;

    if (virtueChosen === "") {
      setErrorOne(true);
    } else {
      setErrorOne(false);
      setVirtuesScore((newScore += 0.5));
    }
    if (whyVirtue === "") {
      setErrorTwo(true);
    } else {
      setErrorTwo(false);
      setVirtuesScore((newScore += 0.5));
    }
    if (usedVirtue === "") {
      setErrorThree(true);
    } else {
      setErrorThree(false);
      setVirtuesScore((newScore += 0.5));
    }
    if (feelUsingVirtue === "") {
      setErrorFour(true);
    } else {
      setErrorFour(false);
      setVirtuesScore((newScore += 0.5));
    }
    if (contUsingVirtue === "") {
      setErrorFive(true);
    } else {
      setErrorFive(false);
      setVirtuesScore((newScore += 0.5));
    }
    if (
      virtueChosen === "" ||
      whyVirtue === "" ||
      usedVirtue === "" ||
      feelUsingVirtue === "" ||
      contUsingVirtue === ""
    ) {
      let valCont = window.confirm(
        "Some fields are empty. Do you want to continue?"
      );
      if (valCont) {
        changeStep();
      } else {
        return;
      }
    } else {
      setVirtuesScore((newScore += 2.5));
      changeStep();
    }
  };

  return (
    <div className="virtueaffirm">
      <Typography component={"div"}>
        <p className="virtueaffirm__left">
          Thank you for selecting those three values. We{" "}
          <strong>recommend</strong> that you <b>think </b>
          about these virtues <strong>often</strong> and use them to{" "}
          <i> make decisions in everyday life.</i>
        </p>
      </Typography>

      <Typography component={"div"}>
        <p className="virtueaffirm__left">
          {" "}
          <b> Exercise:</b> Now, you are going to{" "}
          <span> affirm your virtue.</span>
        </p>
      </Typography>

      <hr />
      <Typography component={"div"}>
        <p>
          Please choose <strong>ONE virtue.</strong> It can the one of the
          virtues you selected or a <i>different one.</i> Then,{" "}
          <strong> write below </strong>about <i>why it matters to you.</i> You
          will also write about a time in the past that you used the virtue to
          <i> solve a problem</i>, and how you want to{" "}
          <u> continue thinking </u>
          about the virtue.
        </p>
      </Typography>

      <hr />

      <div className="virtueaffirm__exercise">
        <Typography component={"div"}>
          <p>What is the virtue that you have chosen?</p>
          <p>
            <i>(Please write the virtue in the box below)</i>
          </p>
        </Typography>
        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          fullWidth={true}
          error={errorOne}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={virtueChosen}
          onChange={(e) => setVirtueChosen(e.target.value)}
        />
        <Typography component={"div"}>
          <p>Why is this virtue important to you?</p>
          <p>
            <i>(Please write 1-2 sentences in the box below)</i>
          </p>
        </Typography>

        {/* below are mutliline */}

        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorTwo}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={whyVirtue}
          onChange={(e) => setWhyVirtue(e.target.value)}
        />
        <Typography component={"div"}>
          <p>What is a time in your life when you used this virtue?</p>
          <p>
            <i>(Please write 1-2 sentences in the box below)</i>
          </p>
        </Typography>
        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorThree}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={usedVirtue}
          onChange={(e) => setUsedVirtue(e.target.value)}
        />
        <Typography component={"div"}>
          <p>How did you feel when you used this virtue?</p>
          <p>
            <i>(Please write 1-2 sentences in the box below)</i>
          </p>
        </Typography>

        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorFour}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={feelUsingVirtue}
          onChange={(e) => setFeelUsingVirtue(e.target.value)}
        />
        <Typography component={"div"}>
          <p>How can you continue to use this virtue now?</p>
          <p>
            Think about what you could do in the next few days or weeks to use
            this virtue.
          </p>
          <p>
            <i>(Please write 1-2 sentences in the box below)?</i>
          </p>
        </Typography>
        <TextField
          id="outlined-full-width"
          style={{ margin: 8 }}
          rows="6"
          fullWidth={true}
          error={errorFive}
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={contUsingVirtue}
          onChange={(e) => setContUsingVirtue(e.target.value)}
        />
      </div>
      <div className="button__container">
        <Button onClick={() => handleChangeStep()} variant="contained">
          Submit Virtue Exercise
        </Button>
      </div>
    </div>
  );
};

export default VirtueAffirm;
