import React from "react";
import "./components/Intro.css";
import logo from "./images/shamiri-logo.png";

const Error = () => {
  return (
    <div className="introduction">
      <div className="introduction__image">
        <img src={logo} alt="shamiri logo" />
      </div>
      <div className="introduction__intro">
        <hr />

        <b> Sorry, the page you are looking for cannot be found.</b>
        <hr />
      </div>
    </div>
  );
};

export default Error;
