import React from "react";
import Typography from "@material-ui/core/Typography";
import Congratulations from "../images/congrats.jpeg";
import "./Congrats.css";

const Congrats = ({ changeStep = (f) => f, changeBack = (f) => f }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  scrollTop();
  return (
    <div className="congrats">
      <img src={Congratulations} alt="congrats" />
      <Typography>
        <p>
          <b> Congratulations!</b> You have completed all the lessons and
          activities for today. Now, we just have a few more questions to
          understand what you think about this program. After that you are done!
        </p>
      </Typography>
      <div className="btn">
        <div className="arrow-button">
          <button onClick={() => changeBack()} className="arrow-left"></button>
        </div>
        <div className="arrow-button">
          <button onClick={() => changeStep()} className="arrow-right"></button>
        </div>
      </div>
    </div>
  );
};

export default Congrats;
