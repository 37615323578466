import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import "./GratitudeIntro.css";

const GratitudeIntro = ({ changeStep = (f) => f }) => {
  return (
    <div className="gratitude__intro">
      <div className="gratitude__intro__content">
        <Typography component={"div"}>
          <p>
            We hope that you enjoyed the lesson about <b>growth</b>.
          </p>
          <p>
            Now, we will learn a little bit about <b>gratitude</b>, something
            that you may already be familiar with gratitude.
          </p>
        </Typography>

        <hr />
      </div>

      <div className="press">
        <Typography component={"div"}>
          Press <b> the button</b> to start lesson on gratitude
        </Typography>
        <hr />
      </div>
      <Button
        onClick={() => changeStep()}
        size="large"
        variant="contained"
        color="primary"
      >
        start
      </Button>
    </div>
  );
};

export default GratitudeIntro;
