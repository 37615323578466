import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "./VirtueExercise.css";

const VirtueExercise = ({
  changeStep,
  virtuesThatMatter,
  virtuesScore,
  setVirtuesScore = (f) => f,
  setVirtuesThatMatter = (f) => f,
}) => {
  const [acceptanceChecked, setAcceptanceChecked] = useState(false);
  const [academicsChecked, setAcademicsChecked] = useState(false);
  const [communityChecked, setCommunityChecked] = useState(false);
  const [compassionChecked, setCompassionChecked] = useState(false);
  const [courageChecked, setCourageChecked] = useState(false);
  const [disciplineChecked, setDisciplineChecked] = useState(false);
  const [environmentChecked, setEnvironmentChecked] = useState(false);
  const [excellenceChecked, setExcellenceChecked] = useState(false);
  const [fairnessChecked, setFairnessChecked] = useState(false);
  const [faithChecked, setFaithChecked] = useState(false);
  const [familyChecked, setFamilyChecked] = useState(false);
  const [friendshipChecked, setFriendshipChecked] = useState(false);
  const [wisdomChecked, setWisdomChecked] = useState(false);
  const [peaceChecked, setPeaceChecked] = useState(false);
  const [patienceChecked, setPatienceChecked] = useState(false);
  const [opennessChecked, setOpennessChecked] = useState(false);
  const [obedienceChecked, setObedienceChecked] = useState(false);
  const [loveChecked, setLoveChecked] = useState(false);
  const [loyaltyChecked, setLoyaltyChecked] = useState(false);
  const [kindnessChecked, setKindnessChecked] = useState(false);
  const [honestyChecked, setHonestyChecked] = useState(false);
  const [helpChecked, setHelpChecked] = useState(false);
  const [hardWorkChecked, setHardWorkChecked] = useState(false);
  const [happinnessChecked, setHappinnessChecked] = useState(false);
  const [gratitudeChecked, setGratitudeChecked] = useState(false);
  const [generosityChecked, setGenerosityChecked] = useState(false);

  let newScore = virtuesScore;

  const handleSelectedVirtues = () => {
    let currentVirtuesList = virtuesThatMatter;
    let newVirtuesList = [];
    if (acceptanceChecked) {
      newVirtuesList.push("Acceptance");
    }
    if (academicsChecked) {
      newVirtuesList.push("Academics");
    }
    if (communityChecked) {
      newVirtuesList.push("Community");
    }
    if (compassionChecked) {
      newVirtuesList.push("Compassion");
    }
    if (courageChecked) {
      newVirtuesList.push("Courage");
    }
    if (disciplineChecked) {
      newVirtuesList.push("Discipline");
    }
    if (environmentChecked) {
      newVirtuesList.push("Environment");
    }
    if (excellenceChecked) {
      newVirtuesList.push("Excellence");
    }
    if (fairnessChecked) {
      newVirtuesList.push("Fairness");
    }
    if (faithChecked) {
      newVirtuesList.push("Faith/Religion");
    }
    if (familyChecked) {
      newVirtuesList.push("Family");
    }
    if (friendshipChecked) {
      newVirtuesList.push("Friendship");
    }
    if (generosityChecked) {
      newVirtuesList.push("Generosity");
    }
    if (gratitudeChecked) {
      newVirtuesList.push("Gratitude");
    }
    if (happinnessChecked) {
      newVirtuesList.push("Happiness");
    }
    if (hardWorkChecked) {
      newVirtuesList.push("Hard Work");
    }
    if (helpChecked) {
      newVirtuesList.push("Helping Others");
    }
    if (honestyChecked) {
      newVirtuesList.push("Honesty");
    }
    if (kindnessChecked) {
      newVirtuesList.push("Kindness");
    }
    if (loveChecked) {
      newVirtuesList.push("Love");
    }
    if (loyaltyChecked) {
      newVirtuesList.push("Loyalty");
    }
    if (obedienceChecked) {
      newVirtuesList.push("Obedience");
    }
    if (opennessChecked) {
      newVirtuesList.push("Openness");
    }
    if (patienceChecked) {
      newVirtuesList.push("Patience");
    }
    if (peaceChecked) {
      newVirtuesList.push("Peace");
    }
    if (wisdomChecked) {
      newVirtuesList.push("Wisdom");
    }

    setVirtuesThatMatter([...currentVirtuesList, newVirtuesList]);
    if (newVirtuesList.length >= 3) {
      setVirtuesScore((newScore += 1.5));
    } else if (newVirtuesList.length === 2) {
      setVirtuesScore((newScore += 1));
    } else if (newVirtuesList.length === 1) {
      setVirtuesScore((newScore += 0.5));
    }
    changeStep();
  };

  return (
    <div className="virtueexercise">
      <Typography component={"div"}>
        <p>
          <b>Activity: </b>In this activity, you will choose a virtue that you
          care about and write about what it means to you.
        </p>
      </Typography>
      <Typography component={"div"}>
        <p className="post-intro">
          <span>Instructions: </span>Please <u>read</u> the list of virtues
          below. Select the THREE virtues that matter most to you.
        </p>
      </Typography>
      <div className="virtue-container">
        <label htmlFor="acceptance">
          <input
            checked={acceptanceChecked}
            onChange={() => setAcceptanceChecked(!acceptanceChecked)}
            type="checkbox"
            id="acceptance"
          />
          Acceptance
        </label>
        <label htmlFor="Academics">
          <input
            checked={academicsChecked}
            onChange={() => setAcademicsChecked(!academicsChecked)}
            type="checkbox"
            id="Academics"
          />
          Academics
        </label>
        <label htmlFor="Community">
          <input
            type="checkbox"
            checked={communityChecked}
            onChange={() => setCommunityChecked(!communityChecked)}
            id="Community"
          />
          Community
        </label>
        <label htmlFor="Compassion">
          <input
            checked={compassionChecked}
            onChange={() => setCompassionChecked(!compassionChecked)}
            type="checkbox"
            id="Compassion"
          />
          Compassion
        </label>
        <label htmlFor="Courage">
          <input
            checked={courageChecked}
            onChange={() => setCourageChecked(!courageChecked)}
            type="checkbox"
            id="Courage"
          />
          Courage
        </label>
        <label htmlFor="Discipline">
          <input
            checked={disciplineChecked}
            onChange={() => setDisciplineChecked(!disciplineChecked)}
            type="checkbox"
            id="Discipline"
          />
          Discipline
        </label>
        <label htmlFor="Environment">
          <input
            checked={environmentChecked}
            onChange={() => setEnvironmentChecked(!environmentChecked)}
            type="checkbox"
            id="Environment"
          />
          Environment
        </label>
        <label htmlFor="Excellence">
          <input
            checked={excellenceChecked}
            onChange={() => setExcellenceChecked(!excellenceChecked)}
            type="checkbox"
            id="Excellence"
          />
          Excellence
        </label>
        <label htmlFor="Fairness">
          <input
            checked={fairnessChecked}
            onChange={() => setFairnessChecked(!fairnessChecked)}
            type="checkbox"
            id="Fairness"
          />
          Fairness
        </label>
        <label htmlFor="Faith">
          <input
            checked={faithChecked}
            onChange={() => setFaithChecked(!faithChecked)}
            type="checkbox"
            id="Faith"
          />
          Faith/Religion
        </label>
        <label htmlFor="Family">
          <input
            checked={familyChecked}
            onChange={() => setFamilyChecked(!familyChecked)}
            type="checkbox"
            id="Family"
          />
          Family
        </label>
        <label htmlFor="Friendship">
          <input
            checked={friendshipChecked}
            onChange={() => setFriendshipChecked(!friendshipChecked)}
            type="checkbox"
            id="Friendship"
          />
          Friendship
        </label>
        <label htmlFor="Generosity">
          <input
            checked={generosityChecked}
            onChange={() => setGenerosityChecked(!generosityChecked)}
            type="checkbox"
            id="Generosity"
          />
          Generosity
        </label>
        <label htmlFor="Gratitude">
          <input
            checked={gratitudeChecked}
            onChange={() => setGratitudeChecked(!gratitudeChecked)}
            type="checkbox"
            id="Gratitude"
          />
          Gratitude
        </label>
        <label htmlFor="Happiness">
          <input
            checked={happinnessChecked}
            onChange={() => setHappinnessChecked(!happinnessChecked)}
            type="checkbox"
            id="Happiness"
          />
          Happiness
        </label>
        <label htmlFor="Work">
          <input
            checked={hardWorkChecked}
            onChange={() => setHardWorkChecked(!hardWorkChecked)}
            type="checkbox"
            id="Work"
          />
          Hard Work
        </label>
        <label htmlFor="Helping">
          <input
            checked={helpChecked}
            onChange={() => setHelpChecked(!helpChecked)}
            type="checkbox"
            id="Helping"
          />
          Helping Others
        </label>
        <label htmlFor="Honesty">
          <input
            checked={honestyChecked}
            onChange={() => setHonestyChecked(!honestyChecked)}
            type="checkbox"
            id="Honesty"
          />
          Honesty
        </label>
        <label htmlFor="Kindness">
          <input
            checked={kindnessChecked}
            onChange={() => setKindnessChecked(!kindnessChecked)}
            type="checkbox"
            id="Kindness"
          />
          Kindness
        </label>
        <label htmlFor="Love">
          <input
            checked={loveChecked}
            onChange={() => setLoveChecked(!loveChecked)}
            type="checkbox"
            id="Love"
          />
          Love
        </label>
        <label htmlFor="Loyalty">
          <input
            checked={loyaltyChecked}
            onChange={() => setLoyaltyChecked(!loyaltyChecked)}
            type="checkbox"
            id="Loyalty"
          />
          Loyalty
        </label>
        <label htmlFor="Obedience">
          <input
            checked={obedienceChecked}
            onChange={() => setObedienceChecked(!obedienceChecked)}
            type="checkbox"
            id="Obedience"
          />
          Obedience
        </label>
        <label htmlFor="Openness">
          <input
            checked={opennessChecked}
            onChange={() => setOpennessChecked(!opennessChecked)}
            type="checkbox"
            id="Openness"
          />
          Openness
        </label>
        <label htmlFor="Patience">
          <input
            checked={patienceChecked}
            onChange={() => setPatienceChecked(!patienceChecked)}
            type="checkbox"
            id="Patience"
          />
          Patience
        </label>
        <label htmlFor="Peace">
          <input
            checked={peaceChecked}
            onChange={() => setPeaceChecked(!peaceChecked)}
            type="checkbox"
            id="Peace"
          />
          Peace
        </label>
        <label htmlFor="Wisdom">
          <input
            checked={wisdomChecked}
            onChange={() => setWisdomChecked(!wisdomChecked)}
            type="checkbox"
            id="Wisdom"
          />
          Wisdom
        </label>
      </div>

      <div className="button__container">
        <Button onClick={() => handleSelectedVirtues()} variant="contained">
          Continue
        </Button>
      </div>
    </div>
  );
};

export default VirtueExercise;
