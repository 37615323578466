import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Button from "@material-ui/core/Button";
import { Animated } from "react-animated-css";

import smallTree from "../images/small-tree.jpg";

import "./GrowthEmailOutro.css";

const GrowthEmailOutro = ({
  changeStep = (f) => f,
  growthEmail,
  setGrowthEmail = (f) => f,
}) => {
  const handleChangeStep = () => {
    if (growthEmail === "") {
      let valConf = window.confirm(
        "Email field is empty. Do you want to continue?"
      );
      if (valConf) {
        changeStep();
      } else {
        return;
      }
    } else {
      changeStep();
      window.alert("We will send you an email soon :) ");
    }
  };

  return (
    <div className="gratitude__emailintro">
      <div className="gratitude__emailintro__content">
        <Typography component={"div"}>
          <p>
            We hope that you enjoyed the lesson about <b>growth</b>.
          </p>
        </Typography>

        <Animated
          animationIn="zoomIn"
          animationOut="fadeOut"
          isVisible={true}
          animationInDuration={3000}
        >
          <div className="smalltree">
            <img src={smallTree} alt="small tree" />
          </div>
        </Animated>
        <Timeline align="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              We can grow in many different ways
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              {" "}
              We can change and become better at things
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              Just like a muscle, your brain gets stronger the more you use it.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent>
              We can grow in many different ways
            </TimelineContent>
          </TimelineItem>
        </Timeline>

        <Typography component={"div"}>
          <p>
            Enter your email to get worksheets that correspond to the module{" "}
          </p>
          <div className="email__address">
            <TextField
              value={growthEmail}
              onChange={(e) => setGrowthEmail(e.target.value)}
              margin="dense"
              type="email"
              id="outlined-size-small"
              label="Email Address"
            />
          </div>
        </Typography>

        <hr />
      </div>
      <Button
        onClick={() => handleChangeStep()}
        size="large"
        variant="contained"
        color="primary"
      >
        continue
      </Button>
    </div>
  );
};

export default GrowthEmailOutro;
