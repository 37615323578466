import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import "./FeedbackTwo.css";

const FeedbackTwo = ({
  changeStep,
  helpfulthingLearned,
  leastHelpfulthingLearned,
  setLeastHelpfulThingLearned = (f) => f,
  setHelpfulThingLearned = (f) => f,
}) => {
  const [growth, setGrowth] = useState(false);
  const [gratitude, setGratitude] = useState(false);
  const [virtues, setVirtues] = useState(false);
  const [growthA, setGrowthA] = useState(false);
  const [gratitudeA, setGratitudeA] = useState(false);
  const [virtuesA, setVirtuesA] = useState(false);

  const handleChange = () => {
    // most helpful
    let currentHelpful = helpfulthingLearned;
    let receivedHelpful = [];
    // least helpful
    let currentLeastHelpful = leastHelpfulthingLearned;
    let receivedLeastHelpful = [];
    // most helpful
    if (growth) {
      receivedHelpful.push("growth");
    }
    if (gratitude) {
      receivedHelpful.push("gratitude");
    }
    if (virtues) {
      receivedHelpful.push("virtues");
    }
    setHelpfulThingLearned([...currentHelpful, receivedHelpful]);
    // least useful
    if (growthA) {
      receivedLeastHelpful.push("growth");
    }
    if (gratitudeA) {
      receivedLeastHelpful.push("gratitude");
    }
    if (virtuesA) {
      receivedLeastHelpful.push("virtues");
    }
    setLeastHelpfulThingLearned([...currentLeastHelpful, receivedLeastHelpful]);
    changeStep();
  };

  return (
    <div className="feedback__two">
      <div className="quizes">
        <Typography>
          <p>
            What is the <b> most helpful</b> thing that you learnt today?
          </p>
        </Typography>

        <label htmlFor="growth">
          <input
            checked={growth}
            onChange={() => setGrowth(!growth)}
            type="checkbox"
            id="growth"
          />
          Growth
        </label>
        <label htmlFor="gratitude">
          <input
            checked={gratitude}
            onChange={() => setGratitude(!gratitude)}
            type="checkbox"
            id="gratitude"
          />
          Gratitude
        </label>
        <label htmlFor="virtues">
          <input
            checked={virtues}
            onChange={() => setVirtues(!virtues)}
            type="checkbox"
            id="virtues"
          />
          Virtues
        </label>
      </div>
      <div className="quizes">
        <Typography>
          <p>
            What is the <b> least helpful </b>thing that you learnt today?
          </p>
        </Typography>

        <label htmlFor="grat">
          <input
            checked={growthA}
            onChange={() => setGrowthA(!growthA)}
            type="checkbox"
            id="grat"
          />
          Growth
        </label>
        <label htmlFor="">
          <input
            checked={gratitudeA}
            onChange={() => setGratitudeA(!gratitudeA)}
            type="checkbox"
            id=""
          />
          Gratitude
        </label>
        <label htmlFor="">
          <input
            checked={virtuesA}
            onChange={() => setVirtuesA(!virtuesA)}
            type="checkbox"
            name=""
            id=""
          />
          Virtues
        </label>
      </div>
      <div className="button__container">
        <Button
          onClick={() => handleChange()}
          variant="contained"
          color="default"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default FeedbackTwo;
